import Header from "./ui/Header";
import {CssBaseline, ThemeProvider} from "@mui/material";
import darkTheme from "./ui/Theme";

function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <div className="App">
                <Header/>
            </div>
        </ThemeProvider>
    );
}

export default App;
