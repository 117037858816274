import {createTheme} from "@mui/material";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        h4: {
            fontWeight: 200,
        },
        fontFamily: [
            'monospace',
            'Roboto',
            'sans-serif',
        ].join(','),
    },
});

export default darkTheme;