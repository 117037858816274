import React from "react";
import {useScrollTrigger} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Typography from '@mui/material/Typography';

function ElevationScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export default function Header(props) {
    return (
        <ElevationScroll>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h4" color="primary" component="div" sx={{flexGrow: 1}}>
                        Bitmask.IT
                    </Typography>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}